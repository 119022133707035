import http from "../http-common"; 

class ApplicationService {
  getAllApplications(searchDTO) {
    console.log(searchDTO)
    return this.getRequest(`/application/applications`, searchDTO);
  }

  getMyApplications(searchDTO) {
    console.log(searchDTO)
    return this.getRequest(`/application/myApps`, searchDTO);
  }

  get(applicationId) {
    return this.getRequest(`/application/${applicationId}`, null);
  }

  findByField(matchData) {
    return this.getRequest(`/application?field=${matchData}`, null);
  }

  addApplication(data) {
    return this.postRequest("/application/addApplication", data);
  }

  update(data) {
  	return http.post("/application/updateApplication", data);
  }
  
  uploadImage(data,applicationId) {
  	return http.postForm("/application/uploadImage/"+applicationId, data);
  }
  generateCode(applicationId) {
    return this.getRequest(`/application/generateCode/${applicationId}`, null);
  }

  cloneApplication(applicationId) {
    return this.getRequest(`/application/cloneApplication/${applicationId}`, null);
  }

  shipApplicationToRepo(applicationId) {
    return this.getRequest(`/application/shipApplicationToRepo/${applicationId}`, null);
  }



	postRequest(url, data) {
        const token = localStorage.getItem('authToken');
        if (token) {
            return http.post(url, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        }
      };

	getRequest(url, params) {
        const token = localStorage.getItem('authToken');
        if (token) {
            return http.get(url, {
            	params: params,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        }
    };

}

export default new ApplicationService();
